import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  circleSmall,
  logoComgate, logoComgate_2x,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import CTA from "../partials/CTA";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
  position: relative;

  ${[theme.media.maxM]} {
    display: block;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

const Title = styled.h1`
  color: ${theme.colors.black};
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  text-wrap: balance;
  margin: 0;
`;
const UnderTitle = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-block: 52px 24px;
`;
const ServiceBox = styled.div`
  display: grid;
  gap: 12px;
  /* padding: 30px 28px 30px 27px; */
  /* border-radius: 24px; */
  /* background: #F8FAFB; */
  margin-bottom: 24px;
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  *:not(h2) {
    opacity: 0.9;
  }
`;
const ServiceBoxTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;
const FAQMainTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-block: 24px 40px;
`;
const FAQSection = styled.details`
  &:last-child {
    margin-bottom: 80px;
  }
  + details {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #ECECEC;
  }
  + h2 {
    margin-top: 80px;
  }
  &[open] > summary::after {
    rotate: 180deg;
  }
`;
const FAQQuestion = styled.summary`
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
  padding-block: 16px;
  margin-block: -16px; 
  color: ${theme.colors.black};
  cursor: pointer;
  &::marker {
    content: "";
  }
  &::after {
    content: "";
    width: 24px;
    height: 24px;
    inset-block-start: 14px;
    inset-inline-end: 0;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clipPath='url(%23clip0_13_151)'%3E%3Cpath d='M7.41 8.59003L12 13.17L16.59 8.59003L18 10L12 16L6 10L7.41 8.59003Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_13_151'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
`;
const FAQAnswer = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  opacity: 0.9;
  margin-top: 16px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const LogoComgateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoComgate = styled.img`
  margin-top: 32px;
  max-width: 130px;
`;

class SubscriptionPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.subscription.h1" }) + " | Eledio.com"
          }
          meta={[
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.subscription.h1" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content>
            <Container>
              <StyledRow spacing={15}>
                <Col6>
                  <Title>
                    <FormattedMessage id="pages.subscription.h1" />
                  </Title>
                  <UnderTitle>
                    <FormattedMessage id="pages.subscription.underH1" />
                  </UnderTitle>
                  <ServiceBox>
                    <ServiceBoxTitle>
                      <FormattedMessage id="pages.subscription.servicePricingTitle" />
                    </ServiceBoxTitle>
                    <FormattedMessage id="pages.subscription.servicePricingText" />
                  </ServiceBox>

                  <FAQMainTitle>
                    <FormattedMessage id="pages.subscription.termsAndConditionsTitle" />
                  </FAQMainTitle>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.termsFAQ.0.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.termsFAQ.0.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.termsFAQ.1.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.termsFAQ.1.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.termsFAQ.2.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.termsFAQ.2.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.termsFAQ.3.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.termsFAQ.3.answer" />
                    </FAQAnswer>
                  </FAQSection>

                  <FAQMainTitle>
                    <FormattedMessage id="pages.subscription.complaintsPolicy.mainTitle" />
                  </FAQMainTitle>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.0.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.0.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.1.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.1.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.2.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.complaintsPolicy.complaintsPolicyFAQ.2.answer" />
                    </FAQAnswer>
                  </FAQSection>

                  <FAQMainTitle>
                    <FormattedMessage id="pages.subscription.deliveryAndPayment.mainTitle" />
                  </FAQMainTitle>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.deliveryAndPayment.deliveryAndPaymentFAQ.0.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.deliveryAndPayment.deliveryAndPaymentFAQ.0.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.deliveryAndPayment.deliveryAndPaymentFAQ.1.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.deliveryAndPayment.deliveryAndPaymentFAQ.1.answer" />
                    </FAQAnswer>
                  </FAQSection>

                  <FAQMainTitle>
                    <FormattedMessage id="pages.subscription.privacyPolicy.mainTitle" />
                  </FAQMainTitle>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.0.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.0.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.1.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.1.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <FAQSection>
                    <FAQQuestion>
                      <FormattedMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.2.question" />
                    </FAQQuestion>
                    <FAQAnswer>
                      <FormattedHTMLMessage id="pages.subscription.privacyPolicy.privacyPolicyFAQ.2.answer" />
                    </FAQAnswer>
                  </FAQSection>
                  <LogoComgateWrapper>
                    <LogoComgate src={logoComgate} srcSet={`${logoComgate}, ${logoComgate_2x} 2x`} alt="Logo Comgate" />
                  </LogoComgateWrapper>
                </Col6>
              </StyledRow>
            </Container>
          </Content>

          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.software.ctatext" })}
            claim={intl.formatMessage({ id: "pages.software.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(SubscriptionPage);
